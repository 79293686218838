<template>
  <article class="blog-post-full padding-x">
    <Preloader
      :loading="isPostLoading || isBlogPostsLoading"
      style="margin: 0 auto"
    />
    <div v-if="post" class="blog-post-full__main">
      <Heading type="h2" text-align="left" class="blog-post-full__heading">
        {{ post.title }}
      </Heading>
      <!--      <span class="blog-post-full__author"> {{ post.author_name }}</span>-->
      <img
        :src="
          post.primary_image
            ? post.primary_image
            : require('../../assets/img/village-sample.jpg')
        "
        alt="Blog image"
        class="blog-post-full__image"
      />
      <p v-html="post.content" class="blog-post-full__text" />
      <ImageCarousel
        v-if="post.images"
        :images="post.images"
        class="blog-post-full__carousel"
      />
    </div>
    <div v-if="blogPosts.length" class="blog-post-full__tip mb-6">
      You might also like:
    </div>
    <div v-if="blogPosts.length" class="blog-post-full__list">
      <BlogPostCard
        v-for="post in randomPosts"
        :key="post.id"
        :data="post"
        :image="post.primary_image"
        @click="() => goToPost(post.id)"
        class="blog-post-full__post"
      />
    </div>
  </article>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import BlogPostCard from "@/components/common/CommonCard";
import ImageCarousel from "@/components/common/ImageCarousel";
import { mapActions } from "vuex";

export default {
  name: "Blog",
  props: {
    blogPosts: {
      type: Array,
      default: () => [],
    },
    isBlogPostsLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageCarousel,
    Preloader,
    Heading,
    BlogPostCard,
  },
  data: () => ({
    isPostLoading: false,
    post: null,
    randomPosts: [],
  }),
  async created() {
    await this.getPost(this.postId);
    if (this.blogPosts.length) {
      console.log("getRandomPosts created");
      this.getRandomPosts();
    }
  },
  computed: {
    postId() {
      return this.$route.params.blog_id;
    },
  },
  watch: {
    blogPosts: {
      handler(value) {
        if (value.length && !this.getRandomPosts.length) {
          this.getRandomPosts();
        }
      },
    },
  },
  methods: {
    ...mapActions(["GET"]),
    getPost(id) {
      this.isPostLoading = true;
      return this.GET({ route: `public/blogpost/${id}` })
        .then((data) => {
          this.post = data.data;
          this.isPostLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPostLoading = false;
        });
    },
    getRandomPosts() {
      if (this.blogPosts.length <= 1 || !this.post) return;
      const max = this.blogPosts.length - 1;
      const currentIndex = this.blogPosts.findIndex(
        (post) => post.id === this.post.id
      );
      const randomIndexes = [
        this.getRandomNum(max),
        this.getRandomNum(max),
        this.getRandomNum(max),
      ];
      const indexesChecked = this.checkRandomNums(
        randomIndexes,
        currentIndex,
        max
      );

      this.randomPosts = [...this.blogPosts].filter((cur, index) =>
        indexesChecked.some((item) => item === index)
      );
    },
    getRandomNum(max) {
      return Math.round(Math.random() * max);
    },
    checkRandomNums(numsArr, curIndex, max) {
      let newNumsArr = [];

      if (
        numsArr[0] === numsArr[1] ||
        numsArr.some((num) => num === curIndex)
      ) {
        newNumsArr = this.alterRandomNums(numsArr, max, curIndex);

        // this.checkRandomNums(newNumsArr, curIndex, max);
      } else {
        newNumsArr = numsArr;
      }

      return newNumsArr;
    },
    alterRandomNums(numsArr, max, curIndex) {
      return numsArr.reduce((acc, cur) => {
        if (acc.some((item) => item === cur) || cur === curIndex) {
          return [...acc, this.getRandomNum(max)];
        }

        return [...acc, cur];
      }, []);
    },
    async goToPost(id) {
      this.post = {};
      this.randomPosts = [];
      this.$router.replace(`/${this.$route.params.id}/blog/${id}`);

      await this.getPost(id);
      this.getRandomPosts();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.blog-post-full {
  padding-top: 108px;

  &__author {
    display: block;
    font-weight: $sansBold;
    color: $black;
    margin-bottom: 8px;
  }

  &__image {
    width: 100%;
    height: 210px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 8px;
  }

  &__text {
    text-align: justify;
    margin-bottom: 44px;
  }

  &__post {
    cursor: pointer;
    &:nth-child(2),
    &:nth-child(3) {
      display: none;
    }
  }

  &__carousel {
    margin-bottom: 100px;
  }

  &__tip {
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media (min-width: $media-xs) {
    &__heading {
      margin-bottom: 4px;
    }

    &__author {
      margin-bottom: 20px;
    }

    &__image {
      height: 328px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }

    &__post {
      &:nth-child(2) {
        display: block;
      }
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 146px;

    &__heading {
      margin-bottom: 16px;
    }

    &__author {
      margin-bottom: 44px;
    }

    &__image {
      height: 494px;
      margin-bottom: 24px;
    }

    &__text {
      margin-bottom: 120px;
    }

    &__list {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
    }

    &__post {
      &:nth-child(3) {
        display: block;
      }
    }
  }

  @media (min-width: $media-md) {
    display: flex;
    align-items: flex-start;

    &__main {
      margin-right: 94px;
      max-width: 60%;
    }

    &__text {
      margin-bottom: 0;
    }

    &__list {
      width: 216px;
      flex-shrink: 0;
      display: grid;
      grid-template-columns: unset;
      grid-gap: 32px;
    }

    &__post {
      .village-card__image {
        height: 306px;
      }
    }
    &__carousel {
      margin-bottom: 10px;
    }
    &__tip {
      display: none;
    }
  }

  @media (min-width: $media-xl) {
    &__main {
      margin-right: 122px;
    }

    &__list {
      width: 270px;
    }

    &__post {
      .village-card__image {
        height: 370px;
      }
    }
  }
}
</style>
